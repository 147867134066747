@import "~bootstrap/scss/bootstrap";
@import "~react-pro-sidebar/dist/scss/styles.scss";

// MAIN COLOR : #f27420
// CHOCOLATE : #7a3410
// RED : #fb1e01
// YELLOW : #fba400

a {
  &:hover {
    text-decoration: none;
    color: initial;
  }
}

.nav-link {
  color: #ffff;

  &:hover {
    color: #7a3410;
  }
}

.nav-item {
  button {
    background-color: #6c0808 !important;
    color: #ffff;
    border-color: #ffff !important;

    &:hover {
      color: #ffff !important;
    }
  }

  .nav-link.active {
    color: #ffff !important;
    background-color: #f27420 !important;
  }
}

.btn-primary {
  border-color: transparent !important;

  &:focus {
    border-color: transparent !important;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}

.main-btn {
  background-color: #f27420 !important;

  &:hover {
    background-color: #e34d00 !important;
  }
}

.btn-google {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  text-transform: none !important;
  color: #fff !important;
  font-weight: 400 !important;
  background-color: #4285f4 !important;
  border-radius: 5px !important;
  &:hover {
    background-color: #3d76d4 !important;
  }
}

.dropdown-menu {
  top: -2px !important;
}

.dim {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.6;
  position: fixed;
  // inset: 71px 0px 0px;
  width: 100%;
  height: 100%;
  z-index: 4;
  will-change: opacity;
  transform: translate3d(0px, 0px, 0px);
  transition: opacity 200ms ease 0ms;
  overflow: hidden;
}

.pro-sidebar {
  background: #6c0808 !important;

  .pro-sidebar-inner {
    background: #6c0808 !important;
  }
}

.sidebar-logo {
  width: 150px;
  height: auto;
  object-fit: contain;
}

.sidebar-top-btn {
  font-weight: bold;
  color: #ffff;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.modal-content {
  // background-color: #6c0808 !important;
  background-image: url("https://main.mobile.doss.co.id/storage/uploads/2021/11/mount-bg-webp2.webp"),
    linear-gradient(#6c0808, #6c0808, #f27420);
}

.modal-header-txt {
  font-size: 24px;
  font-weight: bold;
  color: #ffff;
}

.cursor-pointer {
  cursor: pointer;
}

.logo-idcc {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.main-spons-img {
  width: 250px;
  height: auto;
  object-fit: contain;
}

.second-spons-img {
  width: 150px;
  height: auto;
  object-fit: contain;
}

.gump-logo {
  width: 100px;
  height: auto;
  object-fit: contain;
}

.account-img-container {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: #adadad;
  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
}

.name-txt {
  white-space: nowrap;
}

.logout-btn {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 14px;
  color: #f27420;
  cursor: pointer;
}

.join-btn {
  cursor: pointer;
  background-color: #f27420;
  padding: 3px 10px;
  color: #ffff;
  border-radius: 20px;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06);
  white-space: nowrap;
}

.main-container-first {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("https://main.mobile.doss.co.id/storage/uploads/2021/11/photo-bg-webp.webp"),
      url("https://main.mobile.doss.co.id/storage/uploads/2021/11/mount-bg-webp2.webp"),
      linear-gradient(#6c0808, #6c0808, #f27420);
    background-position: left bottom, right bottom;
    background-attachment: fixed, fixed;
    background-repeat: no-repeat, repeat-x;
    background-size: auto, auto;
    transition: all 0.5s ease-out;
  }
}

.main-container {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-image: url("https://main.mobile.doss.co.id/storage/uploads/2021/11/photo-bg-3-webp.webp"),
      url("https://main.mobile.doss.co.id/storage/uploads/2021/11/mount-bg-webp2.webp"),
      linear-gradient(#f27420, #f27420, #6c0808, #6c0808);
    background-position: left bottom, right bottom;
    background-attachment: fixed, fixed;
    background-repeat: no-repeat, repeat-x;
    background-size: auto, auto;
    transition: all 0.5s ease-out;
  }
}

.bg-mountain {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.3;
}

.fixed-navbar-first {
  position: fixed;
  top: 0;
  background-color: #6c0808;
  z-index: 3;
  transition: all 0.25s ease-in-out;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  background-color: #f27420;
  z-index: 3;
  transition: all 0.25s ease-in-out;
}

.top-main-nav {
  background-color: #f27420;
  padding: 20px 0px;
}

.top-main-menu-container {
  .top-main-menu-first-inactive {
    font-weight: bold;
    color: transparent;
    letter-spacing: 1px;
    border-top: 3px solid #f27420;
    border-bottom: 2px solid #f27420;
    cursor: default;
    transition: all 0.25s ease-in-out;
  }

  .top-main-menu-first {
    font-weight: bold;
    color: #f27420;
    letter-spacing: 1px;
    border-top: 3px solid #f27420;
    border-bottom: 2px solid #f27420;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: #ffff;
      background-color: #f27420;
    }
  }

  .top-main-menu-first-active {
    font-weight: bold;
    color: #ffff;
    letter-spacing: 1px;
    border-top: 3px solid #f27420;
    border-bottom: 2px solid #f27420;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: #f27420;
      color: #ffff;
    }
  }

  .top-main-menu-first-active-2 {
    .dropdown-toggle {
      font-weight: bold;
      color: #ffff;
      letter-spacing: 1px;
      border-top: 3px solid #f27420;
      border-bottom: 2px solid #f27420;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: #f27420;
        color: #ffff;
      }
    }
  }

  .top-main-menu {
    font-weight: bold;
    color: #7a3410;
    letter-spacing: 1px;
    border-top: 3px solid #ffff;
    border-bottom: 2px solid #ffff;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: #ffff;
    }
  }

  .top-main-menu-inactive {
    cursor: default;
    font-weight: bold;
    color: transparent;
    letter-spacing: 1px;
    border-top: 3px solid #ffff;
    border-bottom: 2px solid #ffff;
    transition: all 0.25s ease-in-out;
  }

  .top-main-menu-active {
    font-weight: bold;
    color: #ffff;
    letter-spacing: 1px;
    border-top: 3px solid #ffff;
    border-bottom: 2px solid #ffff;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: #ffff;
      color: #7a3410;
    }
  }

  .top-main-menu-active-2 {
    .dropdown-toggle {
      font-weight: bold;
      color: #ffff;
      letter-spacing: 1px;
      border-top: 3px solid #ffff;
      border-bottom: 2px solid #ffff;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: #ffff;
        color: #7a3410;
      }
    }
  }
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.first-main-content {
  font-weight: bold;
  color: #ffff;
  text-shadow: 0px 2px, 2px 0px, 2px 2px;
  letter-spacing: 2px;
}

.first-main-content-2 {
  font-weight: bold;
  color: #ffff;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
  letter-spacing: 2px;
}

.first-main-content-p {
  text-align: justify;
  color: #ffff;
}

.first-main-content-top {
  text-align: center;
  color: #ffff;
}

.first-main-content-txt-bold {
  font-size: 140%;
  color: #ffff;
  font-weight: bold;
}

.first-main-content-txt-bold-2 {
  font-size: 200%;
  color: #ffff;
  font-weight: bold;
  line-height: 55px;
}

.first-main-content-txt-bold-3 {
  font-size: 350%;
  color: #ffff;
  font-weight: bold;
  line-height: 55px;
}

.first-main-content-txt-bold-4 {
  font-size: 20px;
  color: #ffff;
  font-weight: bold;
}

.first-main-content-txt-bold-5 {
  font-size: 24px;
  color: #ffff;
  font-weight: bold;
  line-height: 30px;
  white-space: nowrap;
}

.first-main-content-big-text {
  font-size: 64px;
  color: #ffff;
  font-weight: bold;
  text-shadow: 0px 2px, 2px 0px, 2px 2px;
  letter-spacing: 6px;
}

.button-red {
  text-align: center !important;
  background-color: #fb1e01 !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  color: #ffff !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: all 0.25s ease-in-out !important;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06) !important;

  &:hover {
    background-color: #e31c02 !important;
    box-shadow: none !important;
  }
}

.button-yellow {
  text-align: center;
  background-color: #fba400 !important;
  font-weight: bold !important;
  border-radius: 15px !important;
  color: #ffff !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: all 0.25s ease-in-out !important;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06) !important;

  &:hover {
    background-color: #e39400 !important;
    box-shadow: none !important;
  }
}

.section-separator {
  height: 100px;
}

.reserved-sponsor {
  height: 60px;
}

.image-card {
  border-radius: 25px;
  width: 200px;
  height: 250px;
  object-fit: cover;
}

.image-card-2 {
  border-radius: 20px;
  width: 150px;
  height: 200px;
  object-fit: cover;
}

.card-text {
  color: #ffff;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}

.card-text-2 {
  font-size: 80%;
  color: #ffff;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}

.card-italic {
  font-style: italic;
}

.first-top-navbar-mobile {
  display: none !important;
}

.item-container-logout {
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.account-name-txt {
  color: grey;
}

.or-text {
  font-size: 80%;
  color: #ffff;
  text-align: center;
}

.here-text {
  font-weight: bold;
  cursor: pointer;
}

.forgot-pass-txt {
  cursor: pointer;
  text-align: end;
  color: #ffff;
  font-size: 80%;
  margin-top: 0.25rem;
}

//ModalSuccess
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid rgb(230, 100, 36);

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: rgb(230, 100, 36);
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(230, 100, 36, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

.modal-success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    color: rgb(230, 100, 36);
  }

  .wait-verif-txt {
    text-align: center;
    color: #ffff;
  }
}

.voucher-txt {
  font-size: 30%;
  text-align: center;
  line-height: 12px;
}

.doss-txt {
  font-size: 50%;
  text-align: center;
  line-height: 22px;
  font-weight: bold;
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

//ModalConfirmOTP
.otp-container {
  width: 100%;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.otp-input {
  width: 50px !important;
  height: 50px !important;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 150%;
  font-weight: 600;
}

.otp {
}

.modal-otp-btn {
  color: white !important;
  background: #ef6128 !important;
  font-weight: 400 !important;
  text-transform: none !important;

  &:disabled {
    background: #f2b9a2 !important;
  }
}

.modal-otp-btn {
  width: 100%;
  color: white !important;
  background: #f27420 !important;
  font-weight: 400 !important;
  text-transform: none !important;

  &:disabled {
    background: #f2b9a2 !important;
  }
}

.modal-confirm-txt {
  color: #ffff;
}

.price-img-container {
  width: 120px;
  min-width: 120px;
  height: 120px;
  min-height: 120px;
  position: relative;

  .price-img-bg {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
  }

  .price-img-content {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
  }
}

.price-img-container-2 {
  width: 80px;
  height: 80px;
  position: relative;

  .price-img-bg-2 {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
  }

  .price-img-content-2 {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
  }
}

.juara-4 {
  justify-content: end;
}

.juara-favorit {
  justify-content: center;
}

.lomba-otomotif-txt {
  font-size: 120%;
  color: #ffff;
}

.otomotif-txt {
  font-size: 120%;
  color: #ffff;
  font-weight: bold;
}

.yuasa-logo {
  width: 250px;
  height: auto;
  object-fit: contain;
}

.doss-logo {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.lensa-logo {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.org-txt {
  color: #ffff;
}

.caffeine-logo {
  width: 150px;
  height: auto;
  object-fit: contain;
}

.gump-logo {
  width: 120px;
  height: auto;
  object-fit: contain;
}

.img-ukm {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.grid-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 20px;
  justify-content: space-between;
  align-content: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-flex-line-pack: start;
}

.close-btn-zoom {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
}

.gallery-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 20px;
  justify-content: space-around;
  align-content: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-flex-line-pack: start;
}

.gallery-img {
  cursor: pointer;
  width: 130px;
  height: 130px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.pg-txt {
  font-size: 90%;
}

.model-txt {
  font-size: 90%;
}

.contest-content {
  max-width: 30.33%;
}

.contest-img-container {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;

  &:hover .contest-img {
    transform: scale(1.05);
  }
}

.contest-img {
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.06);
  transition: all 0.5s ease-in-out;
}

.contest-txt {
  font-size: 120%;
  color: #ffff;
  font-weight: bold;
  text-align: center;
}

.contest-desc-txt {
  font-size: 100%;
  color: #ffff;
}

.tab-contest {
  background-image: linear-gradient(#f27420, #6c0808);
}

.contest-container {
  .nav {
    flex-wrap: nowrap !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
  }
}

.download-btn {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.sm-icon {
  width: 10%;
}

.sm-txt {
  width: 90%;
}

.no-login-container {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.quote-rules {
  background-color: #6c0808;
  border: 1px solid #cccc;
  padding: 5px 10px;
  margin-top: 15px;
}

.hover-txt {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.download-btn-gallery {
  background-color: #6c0808;
  text-align: center;
  border: 1px solid #f27420;
}

.resend-txt {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 767px) {
  .px-45 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1199px) {
  .first-top-navbar {
    display: none !important;
  }
  .first-top-navbar-mobile {
    display: block !important;
  }

  .main-container-first {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      background-image: url("https://main.mobile.doss.co.id/storage/uploads/2021/11/mount-bg-webp2.webp"),
        linear-gradient(#6c0808, #6c0808, #f27420);
      background-position: left bottom, right bottom;
      background-attachment: fixed, fixed;
      background-repeat: no-repeat, repeat-x;
      background-size: auto, auto;
      transition: all 0.5s ease-out;
    }
  }

  .main-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      background-image: url("https://main.mobile.doss.co.id/storage/uploads/2021/11/mount-bg-webp2.webp"),
        linear-gradient(#f27420, #6c0808);
      background-position: left bottom, right bottom;
      background-attachment: fixed, fixed;
      background-repeat: no-repeat, repeat-x;
      background-size: auto, auto;
      transition: all 0.5s ease-out;
    }
  }

  .otp-input {
    width: 45px !important;
    height: 45px !important;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 150%;
    font-weight: 600;
  }

  .first-main-content-txt-bold {
    font-size: 100%;
    color: #ffff;
    font-weight: bold;
  }

  .first-main-content-txt-bold-3 {
    font-size: 150%;
    color: #ffff;
    font-weight: bold;
    line-height: 28px;
  }

  .first-main-content-txt-bold-2 {
    font-size: 100%;
    color: #ffff;
    font-weight: bold;
    line-height: 25px;
  }

  .voucher-txt {
    font-size: 30%;
    text-align: center;
    line-height: 12px;
  }

  .doss-txt {
    font-size: 50%;
    text-align: center;
    line-height: 9px;
    font-weight: bold;
  }

  .juara-4 {
    justify-content: flex-start;
  }

  .juara-favorit {
    justify-content: flex-start;
  }

  .first-main-content {
    font-weight: bold;
    color: #ffff;
  }

  .doss-logo {
    width: 160px;
    height: auto;
    object-fit: contain;
  }

  .lensa-logo {
    width: 160px;
    height: auto;
    object-fit: contain;
  }

  .gallery-container {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-gap: 20px;
    justify-content: space-around;
    align-content: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -ms-flex-line-pack: start;
  }

  .gallery-img {
    cursor: pointer;
    width: 120px;
    height: 120px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s ease-in-out;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}
