* {
  margin: 0;
  font-family: "Lato", "Helvetica", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: "Helvetica", "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Helvetica";
}

.pointer-cursor {
  cursor: pointer;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./assets/fonts/Helvetica.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap");
